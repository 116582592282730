import {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { clearEntity, generateShortUrl } from "../reducers/entityReducer";
import { clearError, setError } from "../reducers/errorReducer";
import Button from "./Button";
import Error from "./Error";
import axios from "axios";

const Search = () => {
    const dispatch = useDispatch();
    const [url, setUrl] = useState('');
    const [captchaObj, setCaptchaObj] = useState(null);
    // 处理按键事件
    const handleClick = async () => {
        var result = captchaObj.getValidate();
        if (!result) {
            dispatch(setError('请先完成认证'));
            captchaObj.verify();
            return
        }
        if (!/^((https|http)?:\/\/)[^\s]+/.test(encodeURI(url))) {
            dispatch(setError('URL 格式有误，请输入 http:// 或 https:// 开头的正确的网址'));
        } else if (url.includes(window.location.href) || !isValidURL(url)) {
            dispatch(setError('非法输入！请检查链接'));
        } else {
            dispatch(clearError());
            dispatch(clearEntity());
            dispatch(generateShortUrl(result.geetest_challenge, result.geetest_validate,result.geetest_seccode,url));
            setUrl('');
        }
    }
    // 监听回车事件
    const handleKeyup = (event) => {
        if (event.keyCode === 13) {
            handleClick();
        }
    }

    useEffect(() =>  {
        // 请求验证码配置数据
        axios.get('/register?timestamp=' + new Date().getTime())
            .then(response => {
                const data = response.data;
                if (window.initGeetest) {
                    // 初始化 Geetest 验证码
                    window.initGeetest({
                        https: 'True',
                        gt: data.gt,
                        challenge: data.challenge,
                        new_captcha: data.new_captcha,
                        offline: !data.success,
                        product: 'bind',
                        width: '100%',
                    }, (captcha) => {
                        setCaptchaObj(captcha);
                        captcha.onReady(() => {
                            // document.getElementById('wait').style.display = 'none';
                        });
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching captcha data:', error);
            });
    }, []);


    return (
        <div className="mb-10">
            <div className="flex">
                <input
                    className="outline-none flex-1 mr-4 px-5 py-3 rounded-lg font-mono text-lg text-black bg-yellow-100 bg-opacity-80 placeholder-yellow-900 placeholder-opacity-30"
                    placeholder="请输入 http:// 或 https:// 开头的网址"
                    value={url}
                    onChange={({ target }) => setUrl(target.value)}
                    onKeyUp={handleKeyup}
                />
                {/*<div id="captcha" className="flex items-center mr-4">*/}
                {/*    <p id="wait" className="show">正在加载验证码......</p>*/}
                {/*</div>*/}
                <Button label=")缩(" onClick={handleClick} />
            </div>
            <Error />
        </div>

    );

}


function isValidURL(url) {
    const pattern = new RegExp('^(https?:\\/\\/)' + // 强制以 http:// 或 https:// 开头
        '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,63}' + // 验证域名 (example.com)
        '|(\\d{1,3}\\.){3}\\d{1,3})' + // 或者IP地址 (192.168.0.1)
        '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // 可选端口和路径
        '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // 可选查询字符串
        '(\\#[-a-zA-Z\\d_]*)?$', 'i'); // 可选哈希
    return pattern.test(url);
}



export default Search;