import axios from "axios";

/**
 * 生成短链接
 * @param challenge
 * @param validate
 * @param second
 * @param {string} url - 原始链接
 * @returns {Promise} - 短链接
 */
const shortUrl = async (challenge, validate, second, url) => {
    const res = await axios.post('/url', { challenge, validate, second, url });
    return res.data;
}

export default { shortUrl };